import React, { useEffect, useState } from "react";
import moment from "moment";
import * as AgendamentoService from "../../services/agendamento";

import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { CheckCircle, Cancel } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
    marginBottom: "8px",
  },
  historicoItem: {
    "& .MuiListItemText-secondary": {
      textTransform: "capitalize",
    },
    "&.ausente": {
      opacity: ".5",
    },
    "&.presente": {},
  },
}));

function Historico() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Histórico",
    });

  const classes = useStyles();

  const [historicoLista, setHistoricoLista] = useState();

  useEffect(() => {
    const getProdutos = async () => {
      const historico = await AgendamentoService.getHistorico();

      setHistoricoLista(historico);
    };

    getProdutos();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Histórico
      </Typography>
      {historicoLista && (
        <List>
          {historicoLista.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index ? <Divider /> : null}
                <ListItem
                  className={`${classes.historicoItem} ${
                    item.presenca ? "presente" : "ausente"
                  }`}
                >
                  <ListItemText
                    primary={moment(item.data).format("DD/MM/yyyy")}
                    secondary={`${moment(item.data).format("dddd")} - ${
                      item.aulas.horario
                    }`}
                  />
                  <ListItemIcon>
                    {item.presenca ? (
                      <CheckCircle style={{ color: "#357a38" }} />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </ListItemIcon>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Historico);
