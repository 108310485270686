import { useEffect, useState } from "react";
import { remoteConfig } from "../utils/firebase";
import { fetchAndActivate, getValue } from "firebase/remote-config";

const useRemoteConfig = (key) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig);
        const fetchedValue = getValue(remoteConfig, key).asBoolean();
        setValue(fetchedValue);
      } catch (error) {
        console.error("Failed to fetch Remote Config", error);
      }
    };

    fetchConfig();
  }, [key]);

  return value;
};

export default useRemoteConfig;
