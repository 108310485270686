import React from "react";
import pkgJson from "../../../package.json";
import { Container, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Facebook, Instagram, YouTube, WhatsApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "32px",
    background: "#f6f6f6",
    color: "#aaa",
    "text-align": "center",
    "& a": {
      transition: "color 0.2s ease-in-out",
      color: "#aaa",
      "text-decoration": "none",
      "&:active, &:hover": {
        color: "#666",
      },
    },
    "& h6": {
      color: "#484848",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3, 0, 0),
      padding: theme.spacing(2),
      "& a.icon": {
        margin: "0 8px",
        "& svg": {
          "font-size": "32px",
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& a.icon": {
        margin: "0 16px",
        "& svg": {
          "font-size": "48px",
        },
      },
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box component="footer" mt={6} className={classes.footer}>
      <Container>
        <Container fixed={true}>
          <Box>
            &copy; Academia Pedro Martinez <br />
            <a
              href="https://www.nuratech.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              By Nuratech
            </a>
            <br />
            v. {pkgJson.version}
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default Footer;
