import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  setCustomSignals,
  fetchAndActivate,
} from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyB-qCPATGNjJvC6vdFMGrQ7ytcItaZjsiU",
  authDomain: "apm-aluno.firebaseapp.com",
  projectId: "apm-aluno",
  storageBucket: "apm-aluno.firebasestorage.app",
  messagingSenderId: "434742810913",
  appId: "1:434742810913:web:f74ede780c5a25f34ac660",
  measurementId: "G-ETBH1GH0MP",
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

setCustomSignals(remoteConfig, {
  ambiente: process.env.NODE_ENV,
});

remoteConfig.defaultConfig = {
  produtos: false,
};

if (process.env.NODE_ENV !== "production") {
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 5000, // 5 seconds for testing only
    fetchTimeoutMillis: 5000, // 5 seconds for testing only
  };
}

fetchAndActivate(remoteConfig)
  .then(() => {
    console.log("Configurações remotas aplicadas!");
  })
  .catch((err) => {
    console.error("Erro ao buscar configurações remotas:", err);
  });

export { remoteConfig };
