import axios from "axios";
import { store } from "../redux/store";
import * as action from "../redux/actions";
import ReactGA from "react-ga4";

const ENV = process.env.NODE_ENV;

export const config = {
  development: {
    API_URL: `${window.location.protocol}//${window.location.hostname}:3000`,
    NURALUTA_API_URL: `${window.location.protocol}//${window.location.hostname}:8080`,
  },
  production: {
    API_URL: "https://apm-api.herokuapp.com",
    NURALUTA_API_URL: "https://nura-bjj-api-c848f5f4dd05.herokuapp.com",
  },
};

export const BASEURL = config[ENV];

export const api = axios.create({
  baseURL: config[ENV].API_URL,
});

const state = store.getState();

if (state?.user?.token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${state.user.token}`;
}

api.interceptors.request.use(
  (config) => {
    store.dispatch(action.showLoading());

    return config;
  },
  (error) => {
    if (window.location.host === "aluno.academiapedromartinez.com.br")
      ReactGA.event("exception", {
        description: "request", // Descrição da exceção
        fatal: false, // Se a exceção foi fatal (fechou o aplicativo ou a página)
        url: error.toJSON().config.url,
        message: error.toJSON().message, // Detalhes da pilha de rastreamento da exceção (opcional)
      });

    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    store.dispatch(action.hideLoading());

    return response;
  },
  (error) => {
    store.dispatch(action.hideLoading());

    const payload = error.response ? error.response : error.toJSON();

    const message =
      payload.data?.message ||
      payload.data?.error ||
      payload.data?.errors ||
      payload.data ||
      payload.message;

    if (window.location.host === "aluno.academiapedromartinez.com.br")
      ReactGA.event("exception", {
        description: "response", // Descrição da exceção
        fatal: false, // Se a exceção foi fatal (fechou o aplicativo ou a página)
        url: error.toJSON().config.url,
        message: error.toJSON().message, // Detalhes da pilha de rastreamento da exceção (opcional)
      });

    // console.log(payload);
    if (error.response?.status === 401) {
      store.dispatch(action.deleteUser());
    }

    if (window.location.hash !== "#/manutencao") {
      if (payload.code === "ERR_NETWORK") {
        window.location.hash = "#/manutencao";
      } else {
        store.dispatch(
          action.showAlert({
            message,
            type: "error",
            title: "Erro",
          })
        );
      }
    }

    return Promise.reject(error);
  }
);
