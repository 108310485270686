import { api, BASEURL } from "../utils/api";

export const Agendar = async (params) => {
  // console.log('Agendar', params);

  const response = await api.post("/aluno/agendamentos", params);

  return response.data;
};

export const Cancelar = async (params) => {
  // console.log('Cancelar', params);

  const response = await api.delete(`/aluno/agendamentos/${params.id}`);

  return response.data;
};

export const getAgendamentos = async (params) => {
  // console.log('getDisponiveis', params);

  const response = await api.get("/aluno/agendamentos", { params });

  return response.data;
};

export const getTreinosRealizados = async () => {
  // console.log('getTreinosRealizados', params);

  const response = await api.get(
    `${BASEURL.NURALUTA_API_URL}/agendamentos/frequencia`
  );

  return response.data;
};

export const getHistorico = async () => {
  // console.log('getTreinosRealizados', params);

  const response = await api.get(
    `${BASEURL.NURALUTA_API_URL}/agendamentos/historico`
  );

  return response.data;
};
