import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  Login,
  SolicitarNovaSenha,
  SalvarSenha,
  Home,
  Agendamento,
  Perfil,
  Manutencao,
  Chat,
  FichaSaude,
  Turmas,
  Produtos,
  Historico,
} from "../pages";

import { PrivateRoute, Header } from "../components";

import useRemoteConfig from "../hook/useRemoteConfig";

const Routes = (props) => {
  const { user } = props;

  const feat_produtos = useRemoteConfig("produtos");

  return (
    <Router>
      <Header user={user} />

      <Switch>
        <Route path="/manutencao">
          <Manutencao />
        </Route>
        <Route path="/senha/:token">
          <SalvarSenha />
        </Route>
        <Route path="/solicitar-nova-senha/:tipo">
          <SolicitarNovaSenha />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/agendamento">
          <Agendamento />
        </PrivateRoute>
        <PrivateRoute path="/historico">
          <Historico />
        </PrivateRoute>
        <PrivateRoute path="/perfil">
          <Perfil />
        </PrivateRoute>
        <PrivateRoute path="/chat">
          <Chat />
        </PrivateRoute>
        <PrivateRoute path="/ficha-saude">
          <FichaSaude />
        </PrivateRoute>
        <PrivateRoute path="/turmas">
          <Turmas />
        </PrivateRoute>
        {feat_produtos && (
          <PrivateRoute path="/produtos">
            <Produtos />
          </PrivateRoute>
        )}
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Routes);
