import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  ExitToApp,
  Menu,
  Person,
  Schedule,
  LocalHospital,
  ListAlt,
  ShoppingCart,
  History,
} from "@material-ui/icons";

import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import useRemoteConfig from "../../hook/useRemoteConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  list: {
    width: 250,
  },
  nome: {
    padding: theme.spacing(2),
  },
}));

const Header = (props) => {
  const { user } = props;

  // console.log(user);

  const [showMenu, setShowMenu] = useState(false);

  const classes = useStyles();

  const Logout = () => {
    localStorage.removeItem("clipper");
    store.dispatch(action.deleteUser());
    amplitude.reset();
  };

  const getName = (user) => {
    if (user.token) {
      return user.apelido && user.apelido !== ""
        ? user.apelido
        : user.nome.split(" ")[0];
    } else {
      return "";
    }
  };

  const feat_produtos = useRemoteConfig("produtos");

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Academia Pedro Martinez
          </Typography>

          {user.token && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setShowMenu(true)}
            >
              <Menu />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        anchor={"right"}
        open={showMenu}
        onClose={() => setShowMenu(false)}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={() => setShowMenu(false)}
        >
          <Typography className={classes.nome} variant="h6">
            Olá, {getName(user)}
          </Typography>
          <List>
            <ListItem button component={Link} to="/agendamento">
              <ListItemText primary="Check-in" />
              <ListItemSecondaryAction>
                <Schedule />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={Link} to="/historico">
              <ListItemText primary="Histórico" />
              <ListItemSecondaryAction>
                <History />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={Link} to="/perfil">
              <ListItemText primary="Perfil" />
              <ListItemSecondaryAction>
                <Person />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={Link} to="/ficha-saude">
              <ListItemText primary="Ficha de Saúde" />
              <ListItemSecondaryAction>
                <LocalHospital />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={Link} to="/turmas">
              <ListItemText primary="Turmas" />
              <ListItemSecondaryAction>
                <ListAlt />
              </ListItemSecondaryAction>
            </ListItem>
            {feat_produtos ? (
              <ListItem button component={Link} to="/produtos">
                <ListItemText primary="Produtos" />
                <ListItemSecondaryAction>
                  <ShoppingCart />
                </ListItemSecondaryAction>
              </ListItem>
            ) : null}
          </List>
          <Divider />
          <List>
            <ListItem button onClick={Logout}>
              <ListItemText primary="Sair" />
              <ListItemSecondaryAction>
                <ExitToApp />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
